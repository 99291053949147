import React, { useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { Oval } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";
import ScrollAnimation from "../../components/ScrollAnimation/ScrollAnimation";

import Phone from "../../images/icons/phone.svg";
import Mail from "../../images/icons/mail.svg";
import Linkedin from "../../images/icons/linkedin.svg";
import Github from "../../images/icons/github.svg";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const contactElements = [
    // {
    //   id: 0,
    //   alt: "phone",
    //   icon: Phone,
    //   element: <a href="tel:+526621687505">+52 (662) 168 7505</a>,
    // },
    {
      id: 1,
      alt: "mail",
      icon: Mail,
      element: (
        <a
          href={`mailto:capobiancoramos@gmail.com?subject=${t(
            "mailSubject"
          )}&body=${t("mailBody")}`}
        >
          capobiancoramos@gmail.com
        </a>
      ),
    },
    {
      id: 2,
      alt: "linkedin",
      icon: Linkedin,
      element: (
        <a href="https://www.linkedin.com/in/jpcapoo">
          linkedin.com/in/jpcapoo
        </a>
      ),
    },
    {
      id: 3,
      alt: "github",
      icon: Github,
      element: <a href="https://www.github.com/JPCapoo">github.com/JPCapoo</a>,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICEID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATEID;
    const formValues = {
      name: form.name.value,
      email: form.email.value,
      phone: form.phone.value,
      message: form.message.value,
    };

    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, formValues);
      toast.success(t("messageSent"));
      form.reset();
    } catch (error) {
      console.error(error);
      toast.error(t("messageError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScrollAnimation id="contact" className="contact maxWidth">
      <Toaster />
      <div className="titleContainer">
        <h2 className="title">
          <span>{t("letsCreate")}</span> {t("something")}{" "}
          <span>{t("together")}</span>
        </h2>
      </div>
      <div className="contactContainer">
        <ul className="contactInfo">
          {contactElements.map((element) => (
            <li key={element.id} className="listElement">
              <img
                src={element.icon}
                alt={element.alt}
                width="40"
                height="40"
                loading="lazy"
                onClick={() => window.open(element.element.props.href)}
              />
              {element.element}
            </li>
          ))}
        </ul>
        <form className="contactForm" onSubmit={handleSubmit}>
          {loading && (
            <div className="loaderContainer">
              <Oval color="#7ef2e7" height={50} width={50} className="loader" />
            </div>
          )}
          <div className="inputContainer">
            <input type="text" id="name" name="name" placeholder=" " required />
            <label htmlFor="name">{t("name")}*</label>
          </div>
          <div className="inputContainer">
            <input
              type="email"
              id="email"
              name="email"
              placeholder=" "
              required
            />
            <label htmlFor="email">{t("email")}*</label>
          </div>
          {/* <div className="inputContainer">
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder=" "
              required
            />
            <label htmlFor="phone">{t("phone")}*</label>
          </div> */}
          <div className="inputContainer">
            <textarea
              id="message"
              name="message"
              placeholder=" "
              required
            ></textarea>
            <label htmlFor="message">{t("message")}*</label>
          </div>
          <button
            className={`btn ${loading && "disabled"}`}
            disabled={loading}
            type="submit"
          >
            {t("send")}
          </button>
        </form>
      </div>
    </ScrollAnimation>
  );
};
